import RequestService from 'services/RequestService';
import { makeApiRequest } from 'actions/api';

import * as fromCookbook from 'reducers/cookbook';

import redirectToLogin from 'helpers/redirectToLogin';

export const UPDATE_REVIEW_COMMENT_FOR_COOKBOOK_REQUEST =
  'UPDATE_REVIEW_COMMENT_FOR_COOKBOOK_REQUEST';
export const UPDATE_REVIEW_COMMENT_FOR_COOKBOOK_RESPONSE =
  'UPDATE_REVIEW_COMMENT_FOR_COOKBOOK_RESPONSE';
export const UPDATE_REVIEW_COMMENT_FOR_COOKBOOK_ERROR = 'UPDATE_REVIEW_COMMENT_FOR_COOKBOOK_ERROR';

export const UPDATE_REVIEW_COMMENT = 'UPDATE_REVIEW_COMMENT';
export const UPDATE_REVIEW_COMMENT_REQUEST = 'UPDATE_REVIEW_COMMENT_REQUEST';
export const UPDATE_REVIEW_COMMENT_RESPONSE = 'UPDATE_REVIEW_COMMENT_RESPONSE';
export const UPDATE_REVIEW_COMMENT_ERROR = 'UPDATE_REVIEW_COMMENT_ERROR';

export const CLOSE_REVIEW_COMMENT = 'CLOSE_REVIEW_COMMENT';

export const handleCloseReviewComment = () => ({
  type: CLOSE_REVIEW_COMMENT,
});

export const updateReviewCommentRequest = (mealSelectionId, reviewId, review) => ({
  type: UPDATE_REVIEW_COMMENT_FOR_COOKBOOK_REQUEST,
  mealSelectionId,
  reviewId,
  review,
});

export const updateReviewCommentResponse = (mealSelectionId, reviewId, response) => ({
  type: UPDATE_REVIEW_COMMENT_FOR_COOKBOOK_RESPONSE,
  mealSelectionId,
  reviewId,
  response,
});

export const updateReviewCommentError = (mealSelectionId, reviewId, error) => ({
  type: UPDATE_REVIEW_COMMENT_FOR_COOKBOOK_ERROR,
  mealSelectionId,
  reviewId,
  error,
});

export function updateReviewCommentForCookbook(mealSelectionId, comment) {
  return (dispatch, getState, { requestService }) => {
    const reviewId = fromCookbook.selectReview(getState(), mealSelectionId).id;
    const review = { comment };
    const params = { review, reviewId };

    dispatch(updateReviewCommentRequest(mealSelectionId, reviewId, review));

    return requestService
      .updateReview({ params })
      .then(response => {
        if (response.status == 401) return redirectToLogin();
        return dispatch(updateReviewCommentResponse(mealSelectionId, reviewId, response));
      })
      .catch(error => dispatch(updateReviewCommentError(mealSelectionId, reviewId, error)));
  };
}

export const updateReviewComment = (reviewId, comment) =>
  makeApiRequest({
    requestType: UPDATE_REVIEW_COMMENT,
    method: RequestService.updateReview,
    params: { reviewId, review: { comment } },
  });
