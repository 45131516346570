import {
  fetchWrapper,
  handleResponse,
  objectToQueryString,
  hcUrl,
  buildOpts,
} from 'services/RequestService/helpers';
import { decamelize } from 'helpers/camelize';

export const getMealSelections = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl(`/user/meal_selections?${objectToQueryString(decamelize(params))}`),
    buildOpts()
  );
  return handleResponse(response);
};
