import keyBy from 'lodash/keyBy';
import { GET_MEALS_RESPONSE } from 'actions/meals';

const transformMealsResponse = response => keyBy(response.mealSelections, 'id');

export default (state = {}, action) => {
  const { response, type } = action;

  switch (type) {
    case GET_MEALS_RESPONSE:
      return {
        ...state,
        ...transformMealsResponse(response),
      };
    default:
      return state;
  }
};

export const selectMeal = (state, id) => state[id];
