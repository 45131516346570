import {
  fetchWrapper,
  handleResponse,
  formatBody,
  hcUrl,
  buildOpts,
} from 'services/RequestService/helpers';

export const createShoppingCart = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/shopping_carts'),
    buildOpts({
      body: formatBody(params),
      method: 'POST',
    })
  );
  return handleResponse(response);
};
