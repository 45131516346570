import get from 'lodash/get';
import { combineReducers } from 'redux';

import comments from './comments';
import currentMealSelectionIds from './currentMealSelectionIds';
import featuredMeals from './featuredMeals';
import meals, * as fromMeals from './meals';
import paging from './paging';
import reviews, * as fromReviews from './reviews';
import reviewStatus from './reviewStatus';
import searchParams from './searchParams';
import status from './status';
import favorites from './favorites';
import mealNotes from './mealNotes';

export default combineReducers({
  comments,
  currentMealSelectionIds,
  meals,
  featuredMeals,
  paging,
  reviews,
  reviewStatus,
  searchParams,
  status,
  favorites,
  mealNotes,
});

const selectCookbook = state => state.cookbook;

export const selectComments = state => selectCookbook(state).comments;
export const selectMealIds = state => selectCookbook(state).currentMealSelectionIds;
export const selectMeals = state => selectCookbook(state).meals;
export const selectFeaturedMeals = state => selectCookbook(state).featuredMeals;

export const selectPaging = state => selectCookbook(state).paging;
export const selectReviews = state => selectCookbook(state).reviews;
export const selectReviewStatus = state => selectCookbook(state).reviewStatus;
export const selectSearchParams = state => selectCookbook(state).searchParams;
export const selectStatus = state => selectCookbook(state).status;
export const selectFavorites = state => selectCookbook(state).favorites;
export const selectMealNotes = state => selectCookbook(state).mealNotes;

export const selectMeal = (state, id) => fromMeals.selectMeal(selectMeals(state), id);
export const selectReview = (state, id) => fromReviews.selectReview(selectReviews(state), id);
export const selectMealNote = (state, id) => get(selectMealNotes(state), id);
