import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';

import { GET_MEALS_RESPONSE } from 'actions/meals';
import { UPDATE_REVIEW_COMMENT_FOR_COOKBOOK_RESPONSE } from 'actions/comments';

import {
  ADD_REVIEW_FOR_COOKBOOK_RESPONSE,
  ADD_REVIEW_FOR_COOKBOOK_ERROR,
  REVIEW_CHANGE_FOR_COOKBOOK,
  UPDATE_REVIEW_FOR_COOKBOOK_ERROR,
  UPDATE_REVIEW_FOR_COOKBOOK_RESPONSE,
} from 'actions/reviews';

const transformMealsResponse = response => keyBy(response.reviews, 'mealSelectionId');
const transformUpdateReviewResponse = response =>
  omit(response, ['meal', 'menu', 'weeklyBasket', 'rating']);

export default (state = {}, action) => {
  const { mealSelectionId, review, response, type } = action;

  switch (type) {
    case GET_MEALS_RESPONSE:
      return {
        ...state,
        ...transformMealsResponse(response),
      };
    case REVIEW_CHANGE_FOR_COOKBOOK:
      return {
        ...state,
        [mealSelectionId]: {
          ...state[mealSelectionId],
          pendingChanges: review,
        },
      };
    case ADD_REVIEW_FOR_COOKBOOK_ERROR:
    case UPDATE_REVIEW_FOR_COOKBOOK_ERROR:
      return {
        ...state,
        [mealSelectionId]: omit(state[mealSelectionId], 'pendingChanges'),
      };
    case ADD_REVIEW_FOR_COOKBOOK_RESPONSE:
      return {
        ...state,
        [mealSelectionId]: response,
      };
    case UPDATE_REVIEW_COMMENT_FOR_COOKBOOK_RESPONSE:
    case UPDATE_REVIEW_FOR_COOKBOOK_RESPONSE:
      return {
        ...state,
        [mealSelectionId]: transformUpdateReviewResponse(response),
      };
    default:
      return state;
  }
};

export const selectReview = (state, id) => state[id];
