import set from 'lodash/fp/set';
import get from 'lodash/get';
import omit from 'lodash/omit';

import {
  ADD_BASKET_RESPONSE,
  UPDATE_BASKET_RESPONSE,
  UNDONATE_BASKET_RESPONSE,
  DONATE_BASKET_RESPONSE,
} from 'actions/baskets';
import {
  ADD_MEAL,
  REMOVE_MEAL,
  SET_BASKET_SERVINGS,
  SET_EDITED_BASKET,
  UNDO_BASKET_EDITS,
  CLEAR_EDITED_BASKET,
  UPDATE_MEAL_SERVINGS,
  ADD_SERVINGS,
  REMOVE_SERVINGS,
} from 'actions/basketEdits';
import { ROUTE_CHANGE } from 'actions/routes';

import * as Routes from 'helpers/routes';

export const updateBasket = ({ meals }) =>
  meals.reduce(
    (hash, meal) => ({
      ...hash,
      [meal.id]: meal.servings,
    }),
    {}
  );

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_BASKET_RESPONSE:
    case UNDONATE_BASKET_RESPONSE:
    case UPDATE_BASKET_RESPONSE:
    case DONATE_BASKET_RESPONSE:
      return updateBasket(action.response.weeklyBasket);
    case UNDO_BASKET_EDITS:
    case SET_EDITED_BASKET:
      return updateBasket(action.basket);
    case ADD_MEAL:
      return set(action.mealId, action.startServings, state);
    case REMOVE_MEAL:
      return omit(state, action.mealId);
    case ADD_SERVINGS: {
      const nextServings = get(state, action.mealId, 0) + action.mealServings;
      return set(action.mealId, nextServings, state);
    }
    case REMOVE_SERVINGS: {
      const nextServings = get(state, action.mealId, 0) - action.mealServings;
      return nextServings > 0
        ? set(action.mealId, nextServings, state)
        : omit(state, action.mealId);
    }
    case SET_BASKET_SERVINGS:
      return action.mealServings;
    case CLEAR_EDITED_BASKET:
      return initialState;
    case UPDATE_MEAL_SERVINGS:
      return set(action.mealId, action.mealServings, state);
    case ROUTE_CHANGE:
      if (action.routeName === Routes.ROOT_PATH_NAME) return initialState;
      return state;
    default:
      return state;
  }
};
