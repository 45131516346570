import {
  GET_MENU_CATEGORIES_RESPONSE,
  GET_MENU_CATEGORIES_ERROR,
  GET_MENU_CATEGORIES_REQUEST,
} from 'actions/menuCategories';

import { GET_BASKET_SUMMARIES_RESPONSE } from 'actions/baskets';

import { backupMenuCategories } from 'lib/constants';

const backupState = backupMenuCategories;

const initialData = {
  loading: false,
  failed: false,
  data: [],
};

const initialState = {};

const initializeMenuCategories = (currentData, menuSlugs) => {
  const menusCategoriesHash = menuSlugs.reduce(
    (hash, slug) => ({ ...hash, [slug]: initialData }),
    {}
  );

  return {
    ...menusCategoriesHash,
    ...currentData,
  };
};

const addMenuCategories = (
  currentData,
  menuSlug,
  menuCategories,
  loading = false,
  failed = false
) => {
  const nextState = {
    loading,
    failed,
    data: menuCategories,
  };

  return {
    ...currentData,
    [menuSlug]: nextState,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BASKET_SUMMARIES_RESPONSE:
      return initializeMenuCategories(
        state,
        action.response.weeklyBaskets.map(wb => wb.menu.slug)
      );
    case GET_MENU_CATEGORIES_REQUEST:
      return addMenuCategories(state, action.params.menuSlug, [], true);
    case GET_MENU_CATEGORIES_RESPONSE:
      return addMenuCategories(
        state,
        action.params.menuSlug,
        action.response.menuCategories,
        false,
        false
      );
    case GET_MENU_CATEGORIES_ERROR:
      return addMenuCategories(state, action.params.menuSlug, backupState, false, true);
    default:
      return state;
  }
};

export const selectMenuCategoriesLoading = (state, slug) => {
  return state[slug] ? state[slug].loading : initialData.loading;
};

export const selectMenuCategoriesData = (state, slug) => {
  return state[slug] ? state[slug].data : backupState;
};
