import pick from 'lodash/pick';

import {
  ADD_BASKET_RESPONSE,
  UPDATE_BASKET_RESPONSE,
  GET_RECEIPT_RESPONSE,
  UNDONATE_BASKET_RESPONSE,
  DONATE_BASKET_RESPONSE,
} from 'actions/baskets';
import { SET_EDITED_BASKET, CLEAR_EDITED_BASKET, UNDO_BASKET_EDITS } from 'actions/basketEdits';
import { ROUTE_CHANGE } from 'actions/routes';

import * as Routes from 'helpers/routes';

const initialState = {
  behavioralDiscountLineItems: [],
  belowLowPricedShippingMin: false,
  belowMinimum: false,
  belowUpchargeableThreshold: false,
  creditDollars: 0,
  creditFormatted: '$0.00',
  creditAndDiscountDollars: 0,
  creditAndDiscountFormatted: '$0.00',
  discountDollars: 0,
  discountFormatted: '$0.00',
  orderSummaryMeals: [],
  recurringDiscountCents: 0,
  recurringDiscountPercent: 0,
  recurringDiscountFormatted: '$0.00',
  recurringDiscountName: '',
  salesTaxFormatted: '$0.00',
  salesTaxCents: 0,
  shippingDollars: 0,
  shippingFormatted: '',
  shippingPriceDiffAmountCentsFormatted: '$0',
  subtotalDollars: 0,
  subtotalFormatted: '$0.00',
  totalDollars: 0,
  totalFormatted: '$0.00',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_BASKET_RESPONSE:
    case UNDONATE_BASKET_RESPONSE:
    case UPDATE_BASKET_RESPONSE:
    case DONATE_BASKET_RESPONSE:
      return {
        ...state,
        ...pick(action.response.weeklyBasket, Object.keys(initialState)),
      };
    case GET_RECEIPT_RESPONSE:
      return {
        ...state,
        ...pick(action.response, Object.keys(initialState)),
      };
    case UNDO_BASKET_EDITS:
    case SET_EDITED_BASKET:
      return {
        ...state,
        ...pick(action.basket, Object.keys(initialState)),
      };
    case CLEAR_EDITED_BASKET:
      return initialState;
    case ROUTE_CHANGE:
      if (action.routeName === Routes.ROOT_PATH_NAME) return initialState;
      return state;
    default:
      return state;
  }
};
