import { ADD_REVIEW_RESPONSE } from 'actions/reviews';
import {
  UPDATE_REVIEW_COMMENT_REQUEST,
  UPDATE_REVIEW_COMMENT_RESPONSE,
  UPDATE_REVIEW_COMMENT_ERROR,
  CLOSE_REVIEW_COMMENT,
} from 'actions/comments';

const startState = {
  showCommentBox: false,
  mealId: null,
  isError: false,
  isSubmitted: false,
  submitPending: false,
};

export default (state = startState, action) => {
  const { response, type } = action;

  switch (type) {
    case ADD_REVIEW_RESPONSE:
      return {
        ...startState,
        showCommentBox: true,
        mealId: response.meal.id,
      };
    case CLOSE_REVIEW_COMMENT:
      return startState;
    case UPDATE_REVIEW_COMMENT_REQUEST:
      return {
        ...state,
        isError: false,
        submitPending: true,
      };
    case UPDATE_REVIEW_COMMENT_RESPONSE:
      return response.meal.id === state.mealId
        ? {
            ...state,
            isError: false,
            isSubmitted: true,
            submitPending: false,
          }
        : state;
    case UPDATE_REVIEW_COMMENT_ERROR:
      return {
        ...state,
        submitPending: false,
        isError: true,
      };
    default:
      return state;
  }
};
