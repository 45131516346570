import get from 'lodash/get';

import {
  SKIP_BASKET_REQUEST,
  SKIP_BASKET_RESPONSE,
  UNSKIP_BASKET_REQUEST,
  UNSKIP_BASKET_RESPONSE,
  UNSKIP_BASKET_ERROR,
  UPDATE_BASKET_ADDRESS_RESPONSE,
  GET_BASKET_REQUEST,
  GET_BASKET_RESPONSE,
  GET_BASKET_ERROR,
  CLEAR_BASKET_ADDRESS,
} from 'actions/baskets';

const initialState = {};
const uninitializedStatus = Object.freeze({
  needsInitialization: true,
  isLoading: false,
  isError: false,
});

const basketRequest = (state, menuSlug) => {
  const nextBasketState = {
    ...state[menuSlug],
    needsInitialization: false,
    isLoading: true,
  };

  return {
    ...state,
    [menuSlug]: nextBasketState,
  };
};

const basketSuccess = (state, menuSlug) => {
  return {
    ...state,
    [menuSlug]: {
      needsInitialization: false,
      isLoading: false,
      isError: false,
    },
  };
};

const basketError = (state, menuSlug) => {
  return {
    ...state,
    [menuSlug]: {
      needsInitialization: false,
      isLoading: false,
      isError: true,
    },
  };
};
const clearRemovedMeals = (state, menuSlug) => {
  return {
    ...state,
    [menuSlug]: {
      ...state[menuSlug],
      removedMeals: [],
    },
  };
};
const addRemovedMeals = (state, menuSlug, response) => {
  if (response.removedMeals && response.removedMeals.length > 0) {
    return {
      ...state,
      [menuSlug]: {
        ...state[menuSlug],
        removedMeals: response.removedMeals,
      },
    };
  } else {
    return state;
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SKIP_BASKET_REQUEST:
    case GET_BASKET_REQUEST:
    case UNSKIP_BASKET_REQUEST:
      return basketRequest(state, action.params.menuSlug);
    case SKIP_BASKET_RESPONSE:
    case UNSKIP_BASKET_RESPONSE:
      return basketSuccess(state, action.params.menuSlug);
    case UNSKIP_BASKET_ERROR:
      if (action.response.status == 404) {
        return basketSuccess(state, action.params.menuSlug);
      } else {
        return basketError(state, action.params.menuSlug);
      }
    case GET_BASKET_RESPONSE:
      return basketSuccess(state, action.params.menuSlug);
    case GET_BASKET_ERROR:
      return basketError(state, action.params.menuSlug);
    case UPDATE_BASKET_ADDRESS_RESPONSE:
      return addRemovedMeals(state, action.params.menuSlug, action.response);
    case CLEAR_BASKET_ADDRESS:
      return clearRemovedMeals(state, action.menuSlug);
    default:
      return state;
  }
};

const selectBasketStatus = (state, menuSlug) => state[menuSlug] || uninitializedStatus;
export const needsInitialization = (state, menuSlug) =>
  get(selectBasketStatus(state, menuSlug), 'needsInitialization', true);
export const isBasketLoading = (state, menuSlug) =>
  get(selectBasketStatus(state, menuSlug), 'isLoading', false);
export const isBasketError = (state, menuSlug) =>
  get(selectBasketStatus(state, menuSlug), 'isError', false);
export const removedMeals = (state, menuSlug) =>
  get(selectBasketStatus(state, menuSlug), 'removedMeals', []);
