import { makeApiRequest } from 'actions/api';

import RequestService from 'services/RequestService';

export const GET_BEHAVIORAL_DISCOUNTS = 'GET_BEHAVIORAL_DISCOUNTS';
export const GET_BEHAVIORAL_DISCOUNTS_REQUEST = 'GET_BEHAVIORAL_DISCOUNTS_REQUEST';
export const GET_BEHAVIORAL_DISCOUNTS_RESPONSE = 'GET_BEHAVIORAL_DISCOUNTS_RESPONSE';
export const GET_BEHAVIORAL_DISCOUNTS_ERROR = 'GET_BEHAVIORAL_DISCOUNTS_ERROR';

export const getBehavioralDiscounts = menuSlug =>
  makeApiRequest({
    requestType: GET_BEHAVIORAL_DISCOUNTS,
    method: RequestService.getBehavioralDiscounts,
    params: { menuSlug },
  });
