import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const GET_USER_REWARDS = 'GET_USER_REWARDS';
export const GET_USER_REWARDS_REQUEST = 'GET_USER_REWARDS_REQUEST';
export const GET_USER_REWARDS_RESPONSE = 'GET_USER_REWARDS_RESPONSE';
export const GET_USER_REWARDS_ERROR = 'GET_USER_REWARDS_ERROR';

export const getUserRewards = () =>
  makeApiRequest({
    requestType: GET_USER_REWARDS,
    method: RequestService.getUserRewards,
  });
