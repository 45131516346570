import * as Sentry from '@sentry/react';

import { getEnvConstants } from 'helpers/envConstants';

const { RUNTIME_ENV, SENTRY_DSN, SENTRY_TRACES_SAMPLE_RATE, SENTRY_REPLAY_ERROR_RATE } =
  getEnvConstants();

export function initializeSentry() {
  if (!SENTRY_DSN) return;

  try {
    Sentry.init({
      beforeSend(event) {
        if (event.exception.values.every(value => value.mechanism.type === 'onerror')) {
          return null;
        } else {
          return event;
        }
      },
      debug: window.location.search?.includes('sentryDebug=true'),
      dsn: SENTRY_DSN,
      allowUrls: [
        /https?:\/\/((.*).herokuapp.com)/,
        /localhost/,
        /tempo-dev/,
        /https?:\/\/((.*).homechef.com)/,
        /https?:\/\/((.*).tempomeals.com)/,
        /webpack/,
        /^\//,
      ],
      environment: RUNTIME_ENV,
      ignoreErrors: [/Load failed/, 'Failed to fetch', 'NetworkError', "Failed to execute 'fetch'"], // eslint-disable-line quotes
      integrations: [
        Sentry.replayIntegration({
          maskAllInputs: true,
          maskAllText: false,
          blockAllMedia: false,
          block: ['.pac-container'], // .pac-container surrounds Google Autocomplete input
        }),
      ],
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: SENTRY_REPLAY_ERROR_RATE,
      tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
    });
    return true;
  } catch (e) {
    console.warn(`WARNING: Sentry initialization failed: ${e}`); // eslint-disable-line no-console
    return null;
  }
}

const sentry = initializeSentry();

export function setUser({ id, email, name }) {
  if (sentry) {
    Sentry.setUser({ id: id, email: email, username: name });
  }
}

export function captureException(error, context = {}) {
  if (sentry) {
    Sentry.captureException(error, { contexts: { 'Provided Context': context } });
  }
}
