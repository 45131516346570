import {
  fetchWrapper,
  handleResponse,
  hcUrl,
  buildOpts,
  objectToQueryString,
} from 'services/RequestService/helpers';

import { decamelize } from 'helpers/camelize';

export const getSuggestedPairings = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl(`/suggested_pairings?${objectToQueryString(decamelize(params, true))}`),
    buildOpts({
      method: 'GET',
    })
  );
  return handleResponse(response);
};
