import get from 'lodash/get';

import { GET_BEHAVIORAL_DISCOUNTS_RESPONSE } from 'actions/behavioralDiscounts';

const initialState = {};

const addBehavioralDiscountsForMenu = (state, menuSlug, behavioralDiscounts) => {
  return {
    ...state,
    [menuSlug]: behavioralDiscounts,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BEHAVIORAL_DISCOUNTS_RESPONSE:
      return addBehavioralDiscountsForMenu(
        state,
        action.response.menuSlug,
        action.response.behavioralDiscounts
      );
    default:
      return state;
  }
};

export const selectBehavioralDiscountsForMenu = (state, menuSlug) => get(state, menuSlug, []);
