import qs from 'qs';
import { omitBy, isEmpty } from 'lodash';
import { getQueryParam } from 'helpers/queryParam';

export const UPDATE_MEAL_FILTER = 'UPDATE_MEAL_FILTER';
export const DASHBOARD_CLEAR_ALL_FILTERS = 'DASHBOARD_CLEAR_ALL_FILTERS';
export const CLEAR_MEAL_FILTER = 'CLEAR_MEAL_FILTER';

export const updateMealFilter = ({ filter, value }) => ({
  type: UPDATE_MEAL_FILTER,
  filter,
  value,
});

export const clearMealFilter = filter => ({
  type: CLEAR_MEAL_FILTER,
  filter,
});

export const clearAllFilters = ({ shouldLog = false } = {}) => ({
  type: DASHBOARD_CLEAR_ALL_FILTERS,
  shouldLog,
});

export const updateMealFilterAndUrl =
  ({ filter, value }) =>
  async dispatch => {
    await dispatch(updateMealFilter({ filter, value }));
    updateUrlWithFilters({ filter, value });
  };

export const clearMealFilterAndUrl = filter => async dispatch => {
  await dispatch(clearMealFilter(filter));
  updateUrlWithFilters({ filter });
};

export const clearAllFiltersAndUrl = () => async dispatch => {
  await dispatch(clearAllFilters());
  clearAllUrlFilters();
};

const updateUrlFilter = ({ filter, stringifiedFilters }) => {
  const filters = getQueryParam(window.location, 'filter') || {};
  filters[filter] = stringifiedFilters;

  return qs.stringify({ filter: omitBy(filters, isEmpty) }, { addQueryPrefix: true });
};

const clearAllUrlFilters = () => {
  window.history.replaceState({ path: '' }, '', window.location.pathname);
};

const updateUrlWithFilters = ({ filter, value = {} }) => {
  const stringifiedFilters = Object.keys(value).toString();
  const updatedPath = updateUrlFilter({ filter, stringifiedFilters });

  window.history.replaceState({ path: updatedPath }, '', updatedPath || window.location.pathname);
};
