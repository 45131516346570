import keyBy from 'lodash/keyBy';
import { GET_FEATURED_MEALS_RESPONSE } from 'actions/meals';

const transformMealsResponse = response => keyBy(response.meals, 'id');

export default (state = {}, action) => {
  const { response, type } = action;

  switch (type) {
    case GET_FEATURED_MEALS_RESPONSE:
      return transformMealsResponse(response);
    default:
      return state;
  }
};
