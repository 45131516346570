/* global API_KEYS */

import get from 'lodash/get';

import { HOME_CHEF } from 'context/BrandContext';

export const getEnvConstants = (brand = HOME_CHEF) => {
  if (window.ENV_CONSTANTS_LOADED !== true) {
    const apiKeys = typeof API_KEYS === 'undefined' ? {} : API_KEYS;
    const rootElement = document.querySelector('html');
    const env = process.env.CYPRESS ? 'development' : get(rootElement, 'dataset.railsEnv', 'test');

    window.REDUX_LOGGING = env === 'production' || env === 'test' ? false : true;
    window.RUNTIME_ENV = env;
    window.AMPLITUDE_API_KEY = get(apiKeys, [env, 'amplitude'], null);
    window.GOOGLE_MAPS_API_KEY = get(apiKeys, [env, 'googleMaps'], null);
    window.GOOGLE_AUTOCOMPLETE_API_KEY = get(apiKeys, [env, 'googleAutocomplete'], null);
    window.SENTRY_DSN = get(apiKeys, [env, 'sentryDsn'], null);
    window.SENTRY_REPLAY_ERROR_RATE = get(apiKeys, [env, 'sentryReplayErrorRate'], 0);
    window.SENTRY_TRACES_SAMPLE_RATE = get(apiKeys, [env, 'sentryTracesSampleRate'], 0.01);
    window.STRIPE_PUBLIC_KEY = get(apiKeys, [env, 'stripe'], 'stripe');
    window.ENV_CONSTANTS_LOADED = true;
    window.IDME_KEYS = get(apiKeys, [env, 'id_me'], {});
  }

  return {
    REDUX_LOGGING: window.REDUX_LOGGING,
    RUNTIME_ENV: window.RUNTIME_ENV,
    AMPLITUDE_API_KEY: window.AMPLITUDE_API_KEY,
    GOOGLE_MAPS_API_KEY: window.GOOGLE_MAPS_API_KEY,
    GOOGLE_AUTOCOMPLETE_API_KEY: window.GOOGLE_AUTOCOMPLETE_API_KEY,
    SENTRY_REPLAY_ERROR_RATE: window.SENTRY_REPLAY_ERROR_RATE,
    SENTRY_DSN: window.SENTRY_DSN,
    SENTRY_TRACES_SAMPLE_RATE: window.SENTRY_TRACES_SAMPLE_RATE,
    STRIPE_PUBLIC_KEY: window.STRIPE_PUBLIC_KEY,
    IDME_REDIRECT_URI: get(window.IDME_KEYS, [brand, 'idme_redirect_uri'], null),
    IDME_ACCOUNT_REDIRECT_URI: get(window.IDME_KEYS, [brand, 'idme_account_redirect_uri'], null),
    IDME_CLIENT_ID: get(window.IDME_KEYS, [brand, 'idme_client_id'], null),
  };
};
