import { GET_USER_REWARDS_RESPONSE } from 'actions/userRewards';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_REWARDS_RESPONSE:
      return action.response.userRewards;
    default:
      return state;
  }
};
