import pick from 'lodash/pick';
import {
  fetchWrapper,
  handleResponse,
  formatBody,
  hcUrl,
  buildOpts,
} from 'services/RequestService/helpers';

const reviewsTransformer = response => ({ reviews: response });

const getPendingReviews = async ({ params }) => {
  const { limit } = params;
  const response = await fetchWrapper(hcUrl(`/reviews/pending?limit=${limit}`), buildOpts());

  return handleResponse(response, reviewsTransformer);
};

const addReviewForCookbook = async ({ params }) => {
  const { mealSelectionId, ...restParams } = params;
  const response = await fetchWrapper(
    hcUrl(`/meal_selections/${mealSelectionId}/reviews`),
    buildOpts({
      method: 'POST',
      body: formatBody(restParams),
    })
  );
  return handleResponse(response);
};

const addReview = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/reviews'),
    buildOpts({
      method: 'POST',
      body: formatBody(params),
    })
  );

  return handleResponse(response);
};

const updateReview = async ({ params: { reviewId, ...restParams } }) => {
  const reviewParams = pick(restParams, ['review.recipeRating', 'review.comment']);
  const response = await fetchWrapper(
    hcUrl(`/reviews/${reviewId}`),
    buildOpts({
      method: 'PATCH',
      body: formatBody(reviewParams),
    })
  );

  return handleResponse(response);
};

export { addReviewForCookbook, addReview, getPendingReviews, updateReview };
