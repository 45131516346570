import {
  ACCOUNT_PAST_ORDERS_REQUEST,
  ACCOUNT_PAST_ORDERS_RESPONSE,
  ACCOUNT_PAST_ORDERS_ERROR,
  ACCOUNT_PAST_ORDER_REQUEST,
  ACCOUNT_PAST_ORDER_RESPONSE,
  ACCOUNT_PAST_ORDER_ERROR,
} from 'actions/orderHistory';

const initialState = {
  pastOrders: {},
  fullPastOrders: {},
  loading: false,
  failed: false,
  showLoading: false,
  showFailed: false,
  numberOfPages: 1,
};

const addFullOrder = (orders, order) => {
  return {
    ...orders,
    [order.menu.id]: order,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_PAST_ORDERS_REQUEST:
      return { ...state, loading: true, failed: false };
    case ACCOUNT_PAST_ORDERS_RESPONSE:
      return {
        ...state,
        loading: false,
        pastOrders: {
          ...state.pastOrders,
          [action.response.page]: action.response.weeklyBaskets,
        },
        numberOfPages: action.response.pages,
      };
    case ACCOUNT_PAST_ORDERS_ERROR:
      return { ...state, loading: false, failed: true };
    case ACCOUNT_PAST_ORDER_REQUEST:
      return { ...state, showLoading: true, showFailed: false };
    case ACCOUNT_PAST_ORDER_RESPONSE:
      return {
        ...state,
        showLoading: false,
        fullPastOrders: addFullOrder(state.fullPastOrders, action.response.weeklyBasket),
      };
    case ACCOUNT_PAST_ORDER_ERROR:
      return { ...state, showLoading: false, showFailed: true };
    default:
      return state;
  }
};

export const selectOrderHistoryPastOrder = (state, menuId) => state.fullPastOrders[menuId];
export const selectOrderHistoryPastOrders = (state, page) => state.pastOrders[page] || [];
export const selectOrderHistoryLoading = state => state.loading;
export const selectOrderHistoryNumberOfPages = state => state.numberOfPages;
export const selectOrderHistoryFailed = state => state.failed;
export const selectPastOrderLoading = state => state.showLoading;
export const selectPastOrderFailed = state => state.showFailed;
