import { GET_MENU_RESPONSE } from 'actions/menus';
import { GET_BASKET_SUMMARIES_RESPONSE } from 'actions/baskets';

import { transformMealForBottomSheet } from 'services/RequestService/helpers';

const initialState = {};

const initializeModalMeals = (currentMenusData, menuSlugs) => {
  const menusHash = menuSlugs.reduce((hash, slug) => ({ ...hash, [slug]: [] }), {});

  return {
    ...menusHash,
    ...currentMenusData,
  };
};

const addModalMealsForMenu = (currentMenusData, menuSlug, menu) => ({
  ...currentMenusData,
  [menuSlug]: menu.modalMeals.map(mm => transformMealForBottomSheet(mm)),
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BASKET_SUMMARIES_RESPONSE:
      return initializeModalMeals(
        state,
        action.response.weeklyBaskets.map(wb => wb.menu.slug)
      );
    case GET_MENU_RESPONSE:
      return addModalMealsForMenu(state, action.params.menuSlug, action.response.menu);
    default:
      return state;
  }
};

export const selectModalMealsForMenu = (state, slug) => state[slug] || [];
