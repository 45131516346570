import reduce from 'lodash/reduce';

import { updateBasket as createServingsObject } from 'reducers/dashboard/basketEdits/servings';
import { setMealOptions } from 'reducers/dashboard/basketEdits/mealOptions';

export const mapBasketStateToParams = (basket, updatedBasket, selectedPairings) => ({
  weekly_basket: buildBasketResource(basket, updatedBasket, selectedPairings),
});

export const mapBasketStateToReceiptParams = (
  basket,
  { selectedMealOptions = {}, selectedPairings = {}, ...updatedBasket },
  includeDiscounts
) => {
  return {
    ...buildBasketResource(basket, { ...updatedBasket, selectedMealOptions, selectedPairings }),
    menu_id: basket.menu.id,
    meal_option_selections: transformMealOptionsByGroupId(selectedMealOptions),
    suggested_pairings: transformBasketEditedSelectedSuggestedPairings(selectedPairings),
    include_discounts: includeDiscounts,
  };
};

export const mapBasketAndMealsToReceiptParams = (
  basket,
  meals,
  selectedPairings,
  includeDiscounts
) => ({
  menu_id: basket.menu.id,
  servings: basket.servings,
  meal_ids: meals.map(meal => meal.id),
  meal_servings: createServingsObject({ meals }),
  meal_option_selections: transformMealOptions(meals, basket.mealOptionSelections),
  suggested_pairings: transformSelectedSuggestedPairings(selectedPairings),
  include_discounts: includeDiscounts,
});

const buildMeals = (mealIds, mealServings, selectedMealOptions) =>
  mealIds.map(mealId => ({
    meal_id: mealId,
    servings: mealServings[mealId],
    meal_option_ids: Object.values(selectedMealOptions[mealId] || {}),
  }));

const buildBasketResource = (
  basket,
  { deliveryDay, servings, mealServings = {}, selectedMealOptions = {}, selectedPairings = {} }
) => {
  const mealIds = Object.keys(mealServings);
  return {
    delivery_day: deliveryDay || basket.deliveryDay,
    servings: servings || basket.servings,
    meal_ids: mealIds,
    meals: buildMeals(mealIds, mealServings, selectedMealOptions),
    meal_servings: mealServings,
    suggested_pairings: transformBasketEditedSelectedSuggestedPairings(selectedPairings),
  };
};

/**
 * Transforms meal option selection objects from the API into an object
 * keyed by meal option group id
 */
export const transformMealOptions = (meals, mealOptionSelections) =>
  meals.reduce(
    (hash, meal) => ({
      ...hash,
      ...setMealOptions(mealOptionSelections, meal.id),
    }),
    {}
  );

/**
 * Transforms nested object of selected meal options keyed by meal id into an object
 * keyed by meal option group id
 * @param {Object<mealId, Object<mealOptionGroupId, mealOptionId>} mealOptions
 * @returns {Object<mealOptionGroupId, mealOptionId>}
 */
const transformMealOptionsByGroupId = mealOptions =>
  Object.values(mealOptions).reduce((obj, mealOptionGroup) => ({ ...obj, ...mealOptionGroup }), {});

/**
 * Transforms array of selected pairings state data (objects) into an array
 * of objects with key value pairs for host meal id and paired meal id
 * @param Array[Object{<hostMealId, Object<suggestedPairing>}]
 * @returns Array[Object<hostMealId, pairedMealId>]
 */
export const transformSelectedSuggestedPairings = suggestedPairings => {
  return reduce(
    suggestedPairings,
    (arr, pairing) => [
      ...arr,
      { hostMealId: pairing.hostMealId, pairedMealId: pairing.suggestedPairing.id },
    ],
    []
  );
};

/**
 * Transforms array of selected pairings state data (objects) into an array
 * of objects with key value pairs for host meal id and paired meal id
 * @param Object{<hostMealId suggestedPairing>}
 * @returns Array[Object<hostMealId, pairedMealId>]
 */
const transformBasketEditedSelectedSuggestedPairings = suggestedPairings => {
  return reduce(
    suggestedPairings,
    (arr, pairedMealId, hostMealId) => [
      ...arr,
      { hostMealId: hostMealId, pairedMealId: pairedMealId },
    ],
    []
  );
};

/**
 * Transforms meal option selection objects from the API into nested objects
 * keyed by both meal id and meal option group id
 * @param {Object<mealId, Array<mealOptionSelection>} mealOptionSelectionsByMeal
 * @param {string} mealId
 * @param {Object} mealOptionSelection
 * @param {string} mealOptionSelection.mealOptionGroupId
 * @param {string} mealOptionSelection.mealOptionId
 * @returns {Object<mealId, Object<mealOptionGroupId, mealOptionId>>}
 */
export const transformMealOptionSelections = mealOptionSelections => {
  const selections = {};
  Object.keys(mealOptionSelections).forEach(mealId => {
    const optionGroups = mealOptionSelections[mealId];
    if (optionGroups && optionGroups.length > 0) {
      selections[mealId] = optionGroups.reduce(
        (obj, group) => ({ ...obj, [group.mealOptionGroupId]: group.mealOptionId }),
        {}
      );
    }
  });
  return selections;
};

export const mapTasteProfileStateToParams = ({ allergies, preferences }, changes = {}) => {
  const allergyParams = allergies.map(allergy => ({
    ...allergy,
    avoid: changes[allergy.name] == null ? allergy.avoid : changes[allergy.name],
  }));

  const preferenceParams = preferences.map(preference => ({
    ...preference,
    interested: changes[preference.name] == null ? preference.interested : changes[preference.name],
  }));

  return { allergies: allergyParams, preferences: preferenceParams };
};
