import {
  fetchWrapper,
  handleResponse,
  hcUrl,
  buildOpts,
  transformMenuResponse,
  objectToQueryString,
} from 'services/RequestService/helpers';

import { decamelize } from 'helpers/camelize';

export const getMenu = async ({ params: { menuSlug } }) => {
  const response = await fetchWrapper(hcUrl(`/menus/${menuSlug}`), buildOpts());
  return handleResponse(response, transformMenuResponse);
};

export const getMenuCategories = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl(`/menu_categories?${objectToQueryString(decamelize(params, true))}`),
    buildOpts({
      method: 'GET',
    })
  );
  return handleResponse(response);
};
