import { GET_MENU_RESPONSE } from 'actions/menus';
import { GET_BASKET_SUMMARIES_RESPONSE } from 'actions/baskets';

const initialState = {};

const initializeModalMealsCategories = (currentMenusData, menuSlugs) => {
  const menusHash = menuSlugs.reduce((hash, slug) => ({ ...hash, [slug]: {} }), {});

  return {
    ...menusHash,
    ...currentMenusData,
  };
};

const addModalMealsForMenu = (currentMenusData, menuSlug, menu) => ({
  ...currentMenusData,
  [menuSlug]: menu.modalMealsCategory,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BASKET_SUMMARIES_RESPONSE:
      return initializeModalMealsCategories(
        state,
        action.response.weeklyBaskets.map(wb => wb.menu.slug)
      );
    case GET_MENU_RESPONSE:
      return addModalMealsForMenu(state, action.params.menuSlug, action.response.menu);
    default:
      return state;
  }
};

export const selectModalMealsCategory = (state, slug) => state[slug] || {};
