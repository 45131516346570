import { makeApiRequest } from 'actions/api';
import { getMealAddons } from 'actions/mealAddons';
import { getOptIns } from 'actions/optIns';
import { getTasteProfile } from 'actions/tasteProfile';
import { getDetailedUser } from 'actions/user';
import RequestService from 'services/RequestService';

export const bootstrapAccountApp = () => async dispatch => {
  const response = await dispatch(getDetailedUser());
  if (response.ok && response.user.completedSignup) {
    dispatch(getMealAddons());
    dispatch(getOptIns());
    dispatch(getTasteProfile());
    dispatch(getPauseReasons());
  }
};

export const GET_PAUSE_REASONS = 'GET_PAUSE_REASONS';
export const GET_PAUSE_REASONS_REQUEST = 'GET_PAUSE_REASONS_REQUEST';
export const GET_PAUSE_REASONS_RESPONSE = 'GET_PAUSE_REASONS_RESPONSE';
export const GET_PAUSE_REASONS_ERROR = 'GET_PAUSE_REASONS_ERROR';

export const getPauseReasons = () =>
  makeApiRequest({
    requestType: GET_PAUSE_REASONS,
    method: RequestService.getPauseReasons,
  });
